import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { copyText } from '@/helpers/actions.helper';

@Component({
  components: { ValidationProvider }
})
export default class BTextInputValidation extends Vue {
  @Prop({ type: String })
  vid?: string;

  @Prop({ type: [Object, String], default: '' })
  rules?: string;

  @Prop()
  value?: string;

  @Prop({
    type: Boolean,
    required: false
  })
  isPassword?: boolean;

  innerValue: string | number = '';
  type = 'text';

  @Watch('innerValue')
  innerValueChange(newVal: string) {
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueChange(newVal: string) {
    this.innerValue = newVal;
  }

  created() {
    const isTypeNumber = this.$attrs.type && this.$attrs.type === 'number';
    this.type = this.isPassword ? 'password' : (this.$attrs.type || 'text');

    if (this.value || isTypeNumber) {
      this.innerValue = this.value || 0;
    }
  }

  togglePass() {
    this.type = this.type === 'password' ? 'text' : 'password';
  }

  copy() {
    if (this.innerValue) {
      copyText(this.innerValue.toString());
    }
  }
}
