import { i18n } from '@/i18n';
import { showToast, TOAST_SUCCESS_CONFIG } from '@/helpers/toast.helper';
import html2canvas from 'html2canvas';
import { generateRandomString } from '@/helpers/string.helper';

// Copy text from input into buffer
export const copyInput = (input: HTMLInputElement) => {
  if (input) {
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
  }
};

// Copy text into buffer
export const copyText = (text: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.style.position = 'fixed'; // avoid scrolling to bottom
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
    showToast(i18n.t('common.copyLink') as string, TOAST_SUCCESS_CONFIG);
  } catch (err) {
    return err;
  } finally {
    document.body.removeChild(textArea);
  }
};

export const takeHtmlScreenshot = (element: HTMLElement) => {
  html2canvas(element).then(canvas => {
    const a = document.createElement('a');
    a.href = canvas.toDataURL();
    a.download = `${generateRandomString()}.png`;
    a.click();
  });
}
